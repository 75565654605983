const data = {
    Alpenhof: {
        Name: 'Haupthaus - Hotel Alpenhof',
        Price: [
            'Comfort Doppelzimmer inkl. Frühstück: 175€ pro Nacht / Zimmer',
            'Comfort Einzelzimmer inkl. Frühstück: 150€ pro Nacht / Zimmer'
        ],
        Checkin: 'Zutritt zum Zimmer erst ab 15 Uhr möglich. Wir empfehlen euch bereits vor der Trauung einzuchecken, da dies nur bis 23 Uhr möglich ist.',
        Description: 'Das Hotel Alpenhof in Bayrischzell ist ein gemütliches 4-Sterne Hotel mit Wellnessbereich, regionaler Küche und traumhafter Lage am Fuße des Wendelstein. Das Hotel liegt 2km von der Hochzeitlocation entfernt.',
        Address: 'Osterhofen 1, 83735 Bayrischzell',
        LocationUrl: 'https://www.google.de/maps/place/Hotel+Alpenhof/@47.6843379,11.9884507,17z/data=!4m9!3m8!1s0x477626a9df7406fb:0x655fd6def0961cf7!5m2!4m1!1i2!8m2!3d47.684443!4d11.98885!16s%2Fg%2F1td_g1tv?entry=ttu&g_ep=EgoyMDI1MDEwNy4wIKXMDSoASAFQAw%3D%3D',
        WebsiteUrl: 'https://www.ihr-alpenhof.de/',
        Image: 'accommodation_1'
    },
    Glockenalm: {
        Name: 'Partnerunterkunft - Glockenalm Wendelstein Mountaincamp',
        Price: [
            'Doppelzimmer: 84€ pro Nacht / Zimmer',
            'Einzelzimmer: 62€ pro Nacht / Zimmer',
            'Frühstück im 6km entfernten Haupthaus Hotel Alpenhof zubuchbar für 15€ pro Person',
            'Der Wellnessbereich im Haupthaus darf ab 9 Uhr kostenfrei genutzt werden.'
        ],
        Checkin: 'Zutritt zum Zimmer erst ab 16 Uhr möglich. Online Check-In vorab und digitaler Zimmerschlüssel.',
        Description: 'Das Wendelstein Mountaincamp ist eine gemütliche und moderne Unterkunft inmitten der atemberaubeneden Naturkulisse von Fischbachau, 4km von der Hochzeitslocation entfernt.',
        Address: 'Glockenalm 1, 83730 Fischbachau',
        LocationUrl: 'https://www.google.com/maps/place/Glockenalm/@47.7020361,11.9176018,17z/data=!4m6!3m5!1s0x477621ee4adad583:0xeb753da1eb572303!8m2!3d47.7024794!4d11.9173546!16s%2Fg%2F11w4fwgbd0?entry=ttu&g_ep=EgoyMDI1MDEwNy4wIKXMDSoASAFQAw%3D%3D',
        WebsiteUrl: 'https://wendelstein-mountain.camp/',
        Image: 'accommodation_2'
    }
}

export default data;
