import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export default function Location({name, description, prices, checkin, address, locationUrl, websiteUrl, image }) {
    return (
        <li className="location">
            <a className="location-map" target="_blank" href={locationUrl}>
                <div className="location-map-image hexagon">
                    <img src={require(`../../content/images/${image}.png`)}></img>
                </div>
                <FontAwesomeIcon className="location-map-icon" icon={faMapMarkerAlt}/>
            </a>
            <div className="location-details">
                <h3 className="accommodation-name">{name}</h3>
                <p>{description}</p>
                {name !== 'Hasenöhrl Hof' && (
                    <>
                        <strong>Preise/Leistung:</strong>
                        <ul className="price-list">
                            {prices?.map((price, index) => (
                                <li key={index}>{price}</li>
                            ))}
                        </ul>
                        <p>
                            <strong>Check-In: </strong>
                            {checkin}
                        </p>
                    </>
                )}
                <p>
                <strong>Adresse: </strong>
                    <a className="location-infos" target="_blank" href={locationUrl}>{address} </a>
                    (Kostenlose Parkplätze)
                </p>
                <p>
                    <strong>Website: </strong>
                    <a className="location-infos" target="_blank" href={websiteUrl}>{name}</a>
                </p>
            </div>
        </li>
    )
}
