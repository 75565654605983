import ImageWall from "../ImageWall";
import IntroText from "../IntroText";
import Feedback from "../Feedback";
import Witnesses from "../Witnesses";
import Timeline from "../Timeline";
import WeddingLocation from "../WeddingLocation";
import WeddingABC from "../WeddingABC";
import Countdown from "../Countdown";
import Accommondation from "../Accommodation";
import BridalCouple from "../BridalCouple";
import FixImage from "../FixImage";
import Footer from "../Footer";
import Navigation from "../Navigation";
import Menu from '../Menu';
import Popup from '../Popup';
import { useLogin } from '../../context/LoginContext';

export default function WeddingComponents() {
    const { loggedIn } = useLogin();

    return (
        <>
            {
                loggedIn ? (
                    <>
                        <Popup />
                        <ImageWall />
                        <Navigation />
                        <IntroText />
                        <Countdown />
                        <BridalCouple />
                        <Feedback />
                        <FixImage />
                        <Witnesses />
                        <Timeline />
                        <WeddingABC />
                        <WeddingLocation />
                        <Accommondation />
                        <Menu />
                        <Footer />
                    </>
                ) : null
            }
        </>
    )
}
