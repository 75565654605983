import "./styles.css";
import  usePopup from "./usePopup"

export default function Popup() {
    const { isPopupVisible, closePopup, handleButtonClick } = usePopup();

    if (!isPopupVisible) {
        return null; // Popup wird nicht gerendert, wenn es nicht sichtbar ist
    }

    return (
        <>
            <div className="overlay" ></div>
            <div className="popup">
                <button className="popup-button popup-close" onClick={closePopup}>
                    x
                </button>
                <img className="popup-image" src={require(`../../content/images/clipart_hotel.png`)}/>
                <div className="popup-body">
                    <h3>Aktuelle Infos zu Euren Übernachtungsmöglichkeiten!</h3>
                    <p>Liebe Hochzeitsgäste,</p>
                    <p>wir haben für Euch zwei nahegelegene Unterkünfte zusammengestellt, damit Ihr bequem in der Nähe der Feier übernachten könnt. Details findet Ihr jetzt auf unserer Website!</p>
                    <button className="popup-button" onClick={handleButtonClick}>Zu den Unterkünften</button>
                </div>
            </div>
        </>
    );
}
